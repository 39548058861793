import * as React from "react";
import CadeauxScene from "../../scenes/Cadeaux/Cadeaux";
import { CadeauxPageContext } from "../../helpers/pagesContext";
import { graphql } from "gatsby";
import { CADEAUX } from "../../helpers/route";
import Layout from "../../components/Layout";

export default function Cadeaux(props) {
  return (
    <CadeauxPageContext.Provider value={props.data}>
      <Layout currentRoute={CADEAUX}>
        <CadeauxScene />
      </Layout>
    </CadeauxPageContext.Provider>
  );
}

export const pageQuery = graphql`
  query {
    contentfulReglement {
      pointsUnit
    }
    contentfulCadeaux {
      title
      headTitleUn
      headTitreDeux
      contentTitle
      cooptation
      cadeaux {
        title
        type
        nom
        description
        illustration {
          filename
          gatsbyImageData
          url
        }
      }
    }
  }
`;
