// extracted by mini-css-extract-plugin
export var arrow = "cadeaux-module--arrow--97024";
export var description = "cadeaux-module--description--5bbb7";
export var gift = "cadeaux-module--gift--b0a04";
export var giftList = "cadeaux-module--giftList--fc812";
export var illustration = "cadeaux-module--illustration--eeee3";
export var infos = "cadeaux-module--infos--0fa21";
export var left = "cadeaux-module--left--ab42e";
export var name = "cadeaux-module--name--b57a1";
export var picture = "cadeaux-module--picture--e9212";
export var position = "cadeaux-module--position--f655b";
export var regleDescription = "cadeaux-module--regleDescription--32b9f";
export var right = "cadeaux-module--right--2f7f5";
export var root = "cadeaux-module--root--0c66f";
export var slideIn = "cadeaux-module--slideIn--044ab";