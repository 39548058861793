import clsx from "clsx";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import Header from "../../components/Header/Header";
import { StarsTitle } from "../../components/StarsTitle/StarsTitle";
import { CadeauxPageContext } from "../../helpers/pagesContext";

import * as styles from "./cadeaux.module.css";

export default function Cadeaux() {
  const {
    contentfulCadeaux: {
      headTitleUn,
      headTitreDeux,
      contentTitle,
      cadeaux,
      cooptation,
    },
  } = useContext(CadeauxPageContext);
  return (
    <div>
      <Header firstLine={headTitleUn} SecondLine={headTitreDeux} />
      <div className={styles.root}>
        <StarsTitle title={contentTitle} />
        <div className={styles.regleDescription}>{cooptation}</div>
        <div className={styles.giftList}>
          {cadeaux.map(({ type, nom, description, illustration }, i) => {
            return (
              <div
                className={clsx(
                  styles.gift,
                  i % 2 === 0 ? styles.right : styles.left
                )}
                key={i}
              >
                <div className={styles.picture}>
                  <div className={styles.illustration}>
                    <GatsbyImage
                      image={illustration.gatsbyImageData}
                      alt={illustration.filename}
                    />
                  </div>
                  <div className={styles.description}>{description}</div>
                </div>
                <div className={styles.infos}>
                  <div className={styles.position}>
                    {i + 1}
                    <sup>{i === 0 ? "er" : "e"}</sup> {type}
                  </div>
                  <div className={styles.name}>
                    {nom} <div className={styles.arrow} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
